import { CelebrationAdminPanelList } from '../components/Celebrations';

function CelebrationsListRoute() {
  return <CelebrationAdminPanelList />;
}

export const Component = CelebrationsListRoute;

export function clientLoader() {
  return null;
}
